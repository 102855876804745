<template>
  <div id="emap"></div>
</template>
<script>
export default {
  props: ['mapState', 'markupPrice'],
  data() {
    return {
      emap: null,
      isMapInitialized: false
    };
  },
  watch: {
    isMapInitialized(value) {
      if (value && this.mapState) {
        this.loadMapState();
      }
    }
  },
  async mounted() {
    this.$store.dispatch('showLoader', { message: 'Loading map, please stand by...' });
    const bingKey = await this.$store.dispatch('tools/getMapToolBingKey');

    const config = {
      bingKey: bingKey || 'AnmDqXY6ZVUXOH0sd2eQn4ikmLgt_UJ4zaxLppH7IGryb0eEmYYKivyBVD8l8Rjr',
      mode: window.Emap.modes.Movers
    };

    if (this.markupPrice) {
      console.log('[ Markup Price from settings ]:', this.markupPrice);
      config.markup = this.markupPrice;
    }

    this.emap = new window.Emap('emap', config);

    this.isMapInitialized = true;
    this.$store.dispatch('hideLoader');

    this.emap.on('save', (state, hash) => {
      console.log('[ STATE ]:', state, hash);
      this.$emit('saveMap', { mapState: state, stateHash: hash });
    });
  },
  methods: {
    loadMapState() {
      console.log('[ Loading map state ... ]');
      this.emap.load(this.mapState);
    }
  }
};
</script>
<style>
div #emap {
  width: 100%;
  text-align: center;
}
div #emap iframe {
  max-width: none !important;
}
</style>
